module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":500,"maxHeight":500},
    },{
      plugin: require('../node_modules/gatsby-emotion-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"mainColor":"brandyRose"},"dark":{"mainColor":"manatee"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WPJBGL4","includeInDevelopment":false,"enableWebVitalsTracking":true,"selfHostedOrigin":"YOUR_SELF_HOSTED_ORIGIN"},
    }]
